import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const AUTH_SUCCESS = 'AUTH_SUCCESS'
const AUTH_LOGOUT = 'AUTH_LOGOUT'

export default new Vuex.Store({
  state: {
    user: localStorage.getItem('tpi-user') ? JSON.parse(localStorage.getItem('tpi-user')) : '',
  },
  getters: {
    getUser: state => {
      return state.user
    },
  },
  mutations: {
    [AUTH_SUCCESS]: (state, user) => {
      state.user = user
    },
    [AUTH_LOGOUT]: (state) => {
      state.user = ''
    },
  },
  actions: {
    setUser: ({ commit }, user) => {
      localStorage.setItem('tpi-user', JSON.stringify(user))
      commit(AUTH_SUCCESS, user)
    },
    signOut: ({ commit }) => {
      localStorage.setItem('tpi-user', '')
      commit(AUTH_LOGOUT)
    },
  },
})