import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/app-check'


const firebaseConfig = {
    apiKey: "AIzaSyDgbDf6zZGkcRhWeeRHn9oe5CD3xpZ9GYY",
    authDomain: "tpi-mathieu.firebaseapp.com",
    projectId: "tpi-mathieu",
    storageBucket: "tpi-mathieu.appspot.com",
    messagingSenderId: "98602181633",
    appId: "1:98602181633:web:60275d41fb5499c5bb0c00"
};

const appCheck = firebase.initializeApp(firebaseConfig).appCheck();

appCheck.activate('6Le2QB8hAAAAAPzwqNrMVEElM2HRmU1zr9WrXMYY', true)

const db = firebase.initializeApp(firebaseConfig).firestore()
const storage = firebase.initializeApp(firebaseConfig).storage()

export { db, storage }