import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const theme = {
    primary: '#F43152',
    secondary: '#BADCEE',
    accent: '#424242',

    avatarBg: '#94D1A0',
    default: '#563DEA',
    info: '#1E88E5',
    success: '#21C1D6',
}

export default new Vuetify({
    theme: {
        themes: {
            light: theme,
            dark: theme
        },
        options: { customProperties: true },
    },

});
