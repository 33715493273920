import { getAuth, updateProfile, createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup, signInWithRedirect, getRedirectResult, GoogleAuthProvider, onAuthStateChanged, signOut } from "firebase/auth";
import router from '@/router'
import { db } from '@/firebase'

const checkRedirectResult = async () => {
    const auth = getAuth();
    const result = await getRedirectResult(auth);

    if (result) {
        await updateUser();

        router.push({ name: "Home" });
    }
}

const signIn = async (email, password, tableId) => {
    const auth = getAuth();

    try {
        await signInWithEmailAndPassword(auth, email, password);

        if (tableId) {
            router.push({ name: "Table", params: { id: tableId } })
        } else {
            router.push({ name: "Home" });
        }
    } catch (error) {
        switch (error.code) {
            case "auth/user-not-found":
                return "Cet utilisateur n'existe pas";
            case "auth/wrong-password":
                return "Le mot de passe est incorrect";
            default:
                return "Une erreur est survenue";
        }
    }
}

const googleSignIn = async (isMobile, tableId) => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();

    try {
        if (isMobile) {
            signInWithRedirect(auth, provider);
        } else {
            const result = await signInWithPopup(auth, provider);
            if (result) {
                await updateUser();

                if (tableId) {
                    router.push({ name: "Table", params: { id: tableId } })
                } else {
                    router.push({ name: "Home" });
                }
            }
        }
    } catch (error) {
        switch (error.code) {
            case "auth/popup-closed-by-user":
                return "La connexion via Google a été annulée.";
        }
    }
}

const register = async (displayName, email, password, tableId) => {
    const auth = getAuth();

    try {
        await createUserWithEmailAndPassword(auth, email, password);
        await updateUser(displayName);

        if (tableId) {
            router.push({ name: "Table", params: { id: tableId } })
        } else {
            router.push({ name: "Home" });
        }
    } catch (error) {
        switch (error.code) {
            case "auth/email-already-in-use":
                return "Cette adresse email est déjà utilisée.";
            case "auth/invalid-email":
                return "Cette adresse email n'est pas valide.";
            case "auth/weak-password":
                return "Le mot de passe doit contenir au moins 6 caractères.";
            default:
                return "Une erreur est survenue.";
        }
    }
}

const getCurrentUser = () => {
    const auth = getAuth();
    return auth.currentUser;
}

const getCurrentUserDocument = async () => {
    const currentUserDocument = await db.collection("users").doc(getCurrentUser().uid).get()
    return currentUserDocument.data()
}

const signUserOut = async () => {
    const auth = getAuth();

    await signOut(auth);

    router.push({ name: "Login" });
}

const updateUser = async (userNameAndSurname) => {
    await updateUserProfile(userNameAndSurname);
    await updateUserDocument(userNameAndSurname);
}

const updateUserProfile = async (userNameAndSurname) => {
    const auth = getAuth();
    const displayName = userNameAndSurname || auth.currentUser.displayName;

    await updateProfile(auth.currentUser, {
        displayName: displayName,
        photoURL: auth.currentUser.photoURL,
    });
}

const updateProfilePicture = async (photoURL) => {
    const currentUser = getCurrentUser();

    await db.collection('users').doc(currentUser.uid).set({
        photoURL: photoURL
    }, { merge: true })
}

const updateUserDocument = async (userNameAndSurname) => {
    const auth = getAuth();
    const displayName = userNameAndSurname || auth.currentUser.displayName;

    await db.collection("users").doc(auth.currentUser.uid).set(
        {
            displayName: displayName,
            photoURL: auth.currentUser.photoURL,
        },
        { merge: true }
    );
}

const checkAuthState = async (to, from, next) => {
    const auth = getAuth()

    onAuthStateChanged(auth, (user) => {
        if (user) {
            !to.meta.guest ? next() : next({ name: 'Home' })
        } else {
            !to.meta.guest ? next({ name: 'Login' }) : next()
        }
    })
}

const checkInviteAuth = async (to, from, next) => {
    const auth = getAuth();

    onAuthStateChanged(auth, async (user) => {
        if (user && user.email == to.params.email) {
            router.push({ name: "Table", params: { tableId: to.params.tableId } })
        } else {
            await signOut(auth);
            next({ name: "Login", params: { tableId: to.params.tableId, email: to.params.email } })
        }

    })

}

export { checkRedirectResult, signIn, googleSignIn, register, checkAuthState, getCurrentUser, getCurrentUserDocument, signUserOut, checkInviteAuth, updateProfilePicture };